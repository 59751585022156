import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';

function RegisterScreen(props) {
    return (
        <SafeAreaView>

        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container:{

    }
});

export default RegisterScreen;

