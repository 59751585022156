import React from 'react';
import { SafeAreaView , StyleSheet} from 'react-native';

function LoginScreen(props) {
    return (
        <SafeAreaView>

        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container:{
        
    }
});

export default LoginScreen;