import React from 'react';
import { Image, ImageBackground, SafeAreaView, StyleSheet, View,Text, TouchableOpacity } from 'react-native';

const StartScreen = ({ navigation }) =>  {
    return (
       <ImageBackground 
       style={styles.background}
       source={require("../assets/espresso.jpg")}>
        <View style={styles.logoContainer}>

        <Image style={styles.logo} source={require("../assets/logo.png")}/>
        <Text style={styles.logoText}>Orderking Dashboard</Text>
        </View>
        <TouchableOpacity style={styles.loginButton} onPress={()=> navigation.navigate('Login')}>
            <Text style={styles.buttonText}>Login</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.registerButton} onPress={()=> navigation.navigate('Registrieren')}>
            <Text style={styles.buttonText}>Registrieren</Text>
        </TouchableOpacity>
       </ImageBackground>
    );
}

const styles = StyleSheet.create({
    background:{
        flex: 1,
        justifyContent: 'flex-end',
        alignItems:'center'
    },
    buttonText:{
        color: '#000',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold'
    },
    logo:{
        width: 200,
        height: 200,
    },
    logoContainer:{
        position:'absolute',
        top: 70,
        alignItems: 'center'

    },
    logoText:{
        color: '#fff',
        textAlign: 'center',
        fontSize: 24
    },
    loginButton:{
        width:'50%',
        height:70,
        backgroundColor: '#fff',
        borderRadius:20,
        marginBottom: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    registerButton:{
        width:'50%',
        height:70,
        backgroundColor: '#CDAD61',
        borderRadius:20,
        marginBottom: 5,
        alignItems: 'center',
        justifyContent: 'center'
    }
});
export default StartScreen;