import { StatusBar } from 'expo-status-bar';
import { Image, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-web';
import StartScreen from './app/screens/StartScreen';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from './app/screens/LoginScreen';
import RegisterScreen from './app/screens/RegisterScreen';
console.log("app gestartet");

const Stack = createNativeStackNavigator();


const MyStack = () => {
  return (
  <Stack.Navigator>
  <Stack.Screen name="Home" component={StartScreen}  options={{ title: 'Welcome' ,headerShown: false }}/>
  <Stack.Screen name="Login" component={LoginScreen} />
  <Stack.Screen name="Registrieren" component={RegisterScreen} />
  </Stack.Navigator>
  );
}

const App = () => {
  return (
    <NavigationContainer>
      <MyStack/>
    </NavigationContainer>
  );
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text:{
    color:'#fff'
  }
});
export default App;